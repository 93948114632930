import { createStore } from 'vuex'
import catchComponents from './catch/'
import user from './user/'

export default createStore({
  state: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    catchComponents,
    user
  }
})
