const state = {
  catch_components: []
}

const mutations = {
  GET_CATCHE_COMPONENTS (state, data) {
    state.catch_components = data
  },
  DELETE_CATCHE_COMPONENTS (state, index) {
    state.catch_components.splice(index, 1)
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
