import { createRouter, createWebHashHistory } from 'vue-router'
import { getURLParameters } from '@/utils/'
import { loginQyWechat } from '@/api'
import { Dialog } from 'vant'

const routes = [
  {
    path: '/',
    redirect: '/home'
  },
  {
    path: '/login',
    name: 'Login',
    meta: { login: true },
    component: () => import('../views/login/')
  },
  {
    path: '/',
    name: 'Layout',
    component: () => import('../views/layout/'),
    children: [
      {
        path: 'home',
        name: 'Home',
        component: () => import('../views/home/')
      },
      {
        path: 'meeting',
        name: 'Meeting',
        component: () => import('../views/meeting/')
      },
      {
        path: 'zhiku',
        name: 'Zhiku',
        component: () => import('../views/zhiku/')
      },
      {
        path: 'library',
        name: 'Library',
        component: () => import('../views/library/')
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/profile/')
      }
    ]
  },
  {
    path: '/fieldList',
    name: 'FieldList',
    component: () => import('../views/list/field')
  },
  {
    path: '/advanceList',
    name: 'AdvanceList',
    component: () => import('../views/list/advance')
  },
  {
    path: '/searchList',
    name: 'SearchList',
    component: () => import('../views/list/search')
  },
  {
    path: '/dashboardList',
    name: 'DashboardList',
    component: () => import('../views/list/dashboard')
  },
  {
    path: '/detail',
    name: 'Detail',
    component: () => import('../views/detail/')
  },
  {
    path: '/advance',
    name: 'Advance',
    component: () => import('../views/search/advance')
  },
  {
    path: '/search',
    name: 'Search',
    component: () => import('../views/search/')
  },
  {
    path: '/subscribe',
    name: 'Subscribe',
    component: () => import('../views/subscribe/')
  },
  // 重置密码
  {
    path: '/passwordReset',
    name: 'PasswordReset',
    meta: { login: true },
    component: () => import('../views/passwordReset/')
  },
  // 完善用户信息completeInfo
  {
    path: '/completeInfo',
    name: 'CompleteInfo',
    meta: { login: true },
    component: () => import('../views/completeInfo/')
  },
  // 用户注册
  {
    path: '/register',
    name: 'Register',
    meta: { login: true },
    component: () => import('../views/register/')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import('../views/profile/feedback/')
  },
  {
    path: '/edtior',
    name: 'Edtior',
    component: () => import('../views/profile/edtior/')
  },
  {
    path: '/account',
    name: 'Account',
    component: () => import('../views/profile/edtior/account')
  },
  {
    path: '/mobile',
    name: 'Mobile',
    component: () => import('../views/profile/edtior/mobile')
  },
  {
    path: '/password',
    name: 'Password',
    component: () => import('../views/profile/edtior/password')
  },
  {
    path: '/setPassword',
    name: 'SetPassword',
    component: () => import('../views/profile/password/')
  },
  {
    path: '/meeting/detail',
    name: 'MeetingDetail',
    component: () => import('../views/meeting/detail')
  },
  {
    path: '/zhiku/list',
    name: 'ZhikuList',
    component: () => import('../views/zhiku/list/')
  },
  {
    path: '/zhiku/detail/guide',
    name: 'ZhikuGuideDetail',
    component: () => import('../views/zhiku/detail/guide')
  },
  {
    path: '/zhiku/detail/literature',
    name: 'ZhikuLiteratureDetail',
    component: () => import('../views/zhiku/detail/literature')
  },
  {
    path: '/zhiku/subscribe',
    name: 'ZhikuSubscribe',
    component: () => import('../views/zhiku/subscribe')
  },
  {
    path: '/zhiku/dashboards',
    name: 'ZhikuDashboards',
    component: () => import('../views/zhiku/list/dashboard-list.vue')
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior (to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { top: 0 }
    }
  }
})

// const base64SafeUrl = (unencoded) => {
//   return Buffer.from(unencoded || '').toString('base64')
// }

const encodeToSafeBase64URL = (string) => {
  const base64 = btoa(string)
  return base64.replace(/\+/g, '-').replace(/\//g, '_').replace(/=/g, '')
}

const qyWechatLogin = () => {
  const uri = location.origin + '/?ticket='
  console.log(`${process.env.VUE_APP_ROCHE_URL}japi/qiye/oauth/ticket/182/${encodeToSafeBase64URL(uri)}`)
  location.href = `${process.env.VUE_APP_ROCHE_URL}japi/qiye/oauth/ticket/182/${encodeToSafeBase64URL(uri)}`
}

router.beforeEach((to, from, next) => {
  if (!to.matched.some(m => (m.meta.login))) {
    const token = window.localStorage.getItem('token')
    if (token) {
      next()
    } else {
      const { ticket } = getURLParameters(location.search)

      if (!ticket) {
        qyWechatLogin()
      } else {
        loginQyWechat({ ticket }).then(res => {
          console.log(res)
          if (res.code === 200) {
            const { token, id } = res.data
            if (token) {
              window.localStorage.setItem('token', token)
              window.localStorage.setItem('userId', id)
              window.localStorage.setItem('has_pwd', res.data.has_pwd)
              next({ path: '/home' })
            }
          } else {
            // next({ path: '/login' })
            Dialog.alert({
              title: '提示',
              message: res.message,
              confirmButtonText: '重新登录',
              confirmButtonColor: '#0c86fe',
              closeOnPopstate: false
            })
              .then(() => {
                // on confirm
                qyWechatLogin()
              })
          }
        })
      }
    }
  } else {
    next()
  }
})

export default router
