import { getUserInfoApi } from '@/api/'
const state = {
  userInfo: {}
}

const mutations = {
  SET_USERINFO (state, data) {
    const reg = /^(\d{3})\d*(\d{4})$/
    const mobile = data.mobile.replace(reg, '$1****$2')
    state.userInfo = {
      ...data,
      mobile
    }
  }
}

const actions = {
  getUserInfo ({ commit }) {
    getUserInfoApi(window.localStorage.getItem('userId')).then(res => {
      console.log(res)
      if (res.code === 200) {
        commit('SET_USERINFO', res.data)
        window.localStorage.setItem('userInfo', JSON.stringify(res.data))
      }
    })
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
