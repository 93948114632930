<template>
  <router-view v-slot="{ Component }">
    <keep-alive :include="includeComponents">
      <component :is="Component" />
    </keep-alive>
  </router-view>
</template>

<script>
import { computed } from 'vue'
import { useStore } from 'vuex'
export default {
  setup() {
    const store = useStore()
    const includeComponents = computed(() => {
      return store.state.catchComponents.catch_components
    })

    return {
      includeComponents
    }
  }
}
</script>

<style lang="less">
@import url('~@/styles/iconfont.css');
html,
body {
  width: 100%;
  height: 100%;
  background: #f3f5fa;
}
body {
  margin: 0;
}
#app {
  width: 100%;
  height: 100%;
}
</style>
