import request from '@/server/'
// 登录
export const loginApi = data => {
  return request({
    url: '/users/login/',
    method: 'post',
    data,
    isLoading: false
  })
}
// 企业微信登录
export const loginQyWechat = data => {
  return request({
    url: '/users/login_by_code/',
    method: 'post',
    data,
    isLoading: false
  })
}

// 获取用户信息
export const getUserInfoApi = id => {
  return request({
    url: `/users/account/${id}`,
    isLoading: false
  })
}
// 获取工作单位
export const getCompanys = params => {
  return request({
    url: '/users/institute/',
    params,
    isLoading: false
  })
}
// 修改基本信息
export const putUserBasic = data => {
  return request({
    url: '/users/reset_basis/',
    method: 'put',
    data,
    isLoading: false
  })
}
// 修改手机号码
export const putUserMobile = data => {
  return request({
    url: '/users/reset_mobile/',
    method: 'put',
    data,
    isLoading: false
  })
}
// 修改密码
export const putPassword = data => {
  return request({
    url: '/users/reset_password/',
    method: 'put',
    data,
    isLoading: false
  })
}
// 提交反馈
export const feedbackApi = data => {
  return request({
    url: '/users/feedback/',
    method: 'post',
    data,
    isLoading: false
  })
}
// 验证码登录
export const codeLoginApi = data => {
  return request({
    url: '/users/login_by_code/',
    method: 'post',
    data,
    isLoading: false
  })
}
// 获取验证码
export const getCaptcha = params => {
  return request({
    url: '/users/captcha/',
    params,
    isLoading: false
  })
}

// 注册时获取单位信息
export const getInstitute = params => {
  return request({
    url: '/users/institute/',
    params,
    isLoading: false
  })
}

// 注册
export const registerApi = (data, method = 'post') => {
  return request({
    url: '/users/register/',
    data,
    method
  })
}

// 忘记密码
export const forgetApi = data => {
  return request({
    url: '/users/forget_password/',
    data,
    method: 'put'
  })
}

// 获取领域列表
export const getFields = params => {
  return request({
    url: '/api/disease_field/',
    params
  })
}
// 获取领域文献列表
export const getFieldLiteratures = params => {
  return request({
    url: '/api/articles/',
    params,
    isLoading: false
  })
}
// 获取领域文献筛选项
export const getFieldTags = params => {
  return request({
    url: '/api/tags/',
    params,
    isLoading: false
  })
}
// 获取领域文献筛选项
export const getAticleDetail = id => {
  return request({
    url: `/api/article/${id}/`
  })
}
// 获取领域文献数量
export const getAticleCount = params => {
  return request({
    url: '/api/articles_count/',
    params
  })
}
// 获取历史检索式
export const getHistoryRetrieval = params => {
  return request({
    url: '/api/retrieval/',
    params
  })
}
// 获取订阅检索式
export const getSubscribe = params => {
  return request({
    url: '/api/retrieval/',
    params
  })
}
// 订阅检索式
export const subscribeApi = data => {
  return request({
    url: '/api/retrieval/',
    method: 'post',
    data
  })
}
// 删除订阅检索式
export const deleteSubscribe = (id, data) => {
  return request({
    url: `/api/retrieval/${id}`,
    method: 'put',
    data,
    isLoading: false
  })
}
// 获取发表趋势图
export const getPublishDate = params => {
  return request({
    url: '/api/dashboard/publish_year/',
    params,
    isLoading: false
  })
}
// 获取作者排行图
export const getAuthorData = params => {
  return request({
    url: '/api/dashboard/author/',
    params,
    isLoading: false
  })
}
// 获取机构排行图
export const getInstituteData = params => {
  return request({
    url: '/api/dashboard/institute/',
    params,
    isLoading: false
  })
}
// 获取相关疾病图
export const getDiseaseData = params => {
  return request({
    url: '/api/dashboard/disease/',
    params,
    isLoading: false
  })
}
// 获取临床应用图
export const getClinicalData = params => {
  return request({
    url: '/api/dashboard/clinical_application/',
    params,
    isLoading: false
  })
}
// 获取研究人群图
export const getPopulationData = params => {
  return request({
    url: '/api/dashboard/population/',
    params,
    isLoading: false
  })
}
// 获取文献类型图
export const getArticleTypeData = params => {
  return request({
    url: '/api/dashboard/article_type/',
    params,
    isLoading: false
  })
}
// 获取语言类型图
export const getLanguageData = params => {
  return request({
    url: '/api/dashboard/language/',
    params,
    isLoading: false
  })
}
// 获取影响因子图
export const getImpactFactorData = params => {
  return request({
    url: '/api/dashboard/impact_factor/',
    params,
    isLoading: false
  })
}
// 获取高频关键词图
export const getKeywordsData = params => {
  return request({
    url: '/api/dashboard/keywords/',
    params,
    isLoading: false
  })
}
// 我的图书馆
export const getLibraryData = params => {
  return request({
    url: '/users/collections/',
    params,
    isLoading: false
  })
}
// 我的图书馆标签
export const getLibraryTags = params => {
  return request({
    url: '/users/collection_tags/',
    params,
    isLoading: false
  })
}
// 收藏
export const collectApi = data => {
  return request({
    url: '/users/collections/',
    method: 'post',
    data,
    isLoading: false
  })
}
// 取消收藏
export const cancelCollectApi = (id, data) => {
  return request({
    url: `/users/collection/${id}/`,
    method: 'put',
    data,
    isLoading: false
  })
}
// 设置初始密码
export const setInitPwd = data => {
  return request({
    url: '/users/init_password/',
    method: 'put',
    data,
    isLoading: false
  })
}
// 获取会议列表
export const getMeetingList = params => {
  return request({
    url: '/meeting/meetings/',
    params,
    isLoading: false
  })
}
// 获取会议标签列表
export const getMeetingLabel = params => {
  return request({
    url: '/meeting/label_types/',
    params,
    isLoading: false
  })
}
// 获取会议详情
export const getMeetingDetail = (id, params) => {
  return request({
    url: `/meeting/meeting/${id}/`,
    params
  })
}
// 获取会议标签列表
export const getMeetingLabelList = params => {
  return request({
    url: '/meeting/meeting_labels/',
    params,
    isLoading: false
  })
}
// 获取领域标签列表
export const getMeetingFieldList = params => {
  return request({
    url: '/meeting/field_labels/',
    params,
    isLoading: false
  })
}
// 获取生物标记物标签列表
export const getMeetingBiomarkerList = params => {
  return request({
    url: '/meeting/bio_labels/',
    params,
    isLoading: false
  })
}
// 文件下载
export const downloadFile = data => {
  return request({
    url: '/meeting/files/download/',
    method: 'post',
    data,
    responseType: 'blob'
  })
}
// 获取菜单列表
export const fetchMenus = params => {
  return request({
    url: '/users/permission_modules/',
    params
  })
}
