import * as axios from 'axios'

import { Toast } from 'vant'

const baseURL = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_BATH_API_URL : ''

const instance = axios.create({
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*'
  },
  time: 20000,
  maxContentLength: 4000,
  isLoading: true,
  baseURL
})

// 请求拦截
instance.interceptors.request.use(
  (config) => {
    // 设置tokens
    const token = window.localStorage.getItem('token')
    if (token) {
      config.headers.authorization = `Bearer ${token}`
    }
    if (config.isLoading) {
      Toast.loading({
        message: '加载中...',
        forbidClick: true,
        loadingType: 'spinner',
        duration: 0
      })
    }
    return config
  },
  (error) => {
    Toast.clear()
    Promise.reject(error)
  }
)

// 响应拦截
instance.interceptors.response.use(
  res => {
    Toast.clear()
    return res.data
  },
  err => {
    console.log(err.response.status)
    if (err.response.status === 401) {
      window.localStorage.removeItem('wxUserInfo')
      window.localStorage.removeItem('token')
      window.localStorage.removeItem('userId')
      window.location.href = `${location.origin}`
    } else {
      Toast.clear()
      Toast({
        message: '请求错误',
        forbidClick: true,
        duration: 1000
      })
      Promise.reject(err)
    }
  }
)

export default instance
