import { downloadFile } from '@/api/'

function typeOf (obj) {
  const toString = Object.prototype.toString
  const map = {
    '[object Boolean]': 'boolean',
    '[object Number]': 'number',
    '[object String]': 'string',
    '[object Function]': 'function',
    '[object Array]': 'array',
    '[object Date]': 'date',
    '[object RegExp]': 'regExp',
    '[object Undefined]': 'undefined',
    '[object Null]': 'null',
    '[object Object]': 'object'
  }
  return map[toString.call(obj)]
}

// deepCopy
function deepCopy (data) {
  const t = typeOf(data)
  let o

  if (t === 'array') {
    o = []
  } else if (t === 'object') {
    o = {}
  } else {
    return data
  }

  if (t === 'array') {
    for (let i = 0; i < data.length; i++) {
      o.push(deepCopy(data[i]))
    }
  } else if (t === 'object') {
    for (const i in data) {
      o[i] = deepCopy(data[i])
    }
  }
  return o
}

function deleteObjEmptyData (obj) {
  const newObj = deepCopy(obj)
  for (const item in obj) {
    const t = typeOf(newObj[item])
    if (t === 'array') {
      if (!newObj[item].length) {
        delete newObj[item]
      } else {
        newObj[item] = newObj[item].join(' ')
      }
    } else {
      if (
        newObj[item] === '' ||
        newObj[item] === null ||
        newObj[item] === undefined
      ) {
        delete newObj[item]
      }
    }
  }
  return newObj
}
// debounce
function debounce (func, wait, immediate = false) {
  let timeout
  return function () {
    const context = this
    const args = arguments

    if (timeout) clearTimeout(timeout)
    if (immediate) {
      var callNow = !timeout
      timeout = setTimeout(() => {
        timeout = null
      }, wait)
      if (callNow) func.apply(context, args)
    } else {
      timeout = setTimeout(() => {
        func.apply(context, args)
      }, wait)
    }
  }
}

const getToday = () => {
  var today = new Date()
  today.setHours(0, 0, 0, 0)
  return today
}

const getURLParameters = url =>
  (url.match(/([^?=&]+)(=([^&]*))/g) || []).reduce(
    (a, v) => {
      a[v.slice(0, v.indexOf('='))] = v.slice(v.indexOf('=') + 1)

      return a
    },
    {}
  )

const filePreview = (url) => {
  if (!url) return
  const fielMIMEMap = new Map([
    ['png', 'image/png'],
    ['jpg', 'image/jpg'],
    ['jpeg', 'image/jpeg'],
    ['gif', 'image/gif'],
    ['bmp', 'image/bmp']
  ])
  const fileName = decodeURIComponent(url).split('/').pop()
  const fileType = fileName.split('.').pop()
  downloadFile({ file: url }).then(res => {
    if (res.type === 'application/pdf') {
      window.URL = window.URL || window.webkitURL
      const pdfFile = window.URL.createObjectURL(res)
      // window.open(pdfFile + '#toolbar=0')
      window.location.href = pdfFile + '#toolbar=0'
      setTimeout(() => {
        window.URL.revokeObjectURL(pdfFile)
      }, 100)
      return
    }
    if (res.type === 'application/octet-stream' && fielMIMEMap.get(fileType)) {
      window.URL = window.URL || window.webkitURL
      const file = window.URL.createObjectURL(
        new Blob([res], { type: fielMIMEMap.get(fileType) })
      )
      // window.open(file)
      window.location.href = file
      return
    }
    console.log('文件类型不支持预览')
  })
}

// 删除对象中的空属性，包括空字符串、null、undefined、空数组
const filterEmpty = obj => {
  const newObj = {}
  Object.keys(obj).forEach(key => {
    if (obj[key] !== '' && obj[key] !== null && obj[key] !== undefined && obj[key].length !== 0) {
      newObj[key] = obj[key]
    }
  })
  return newObj
}

export {
  deepCopy,
  debounce,
  deleteObjEmptyData,
  getToday,
  getURLParameters,
  filePreview,
  filterEmpty
}
